import React from 'react';

import Category from 'components/help/category';

import { privacy } from 'data/helpArticles';

export default () => (
  <Category
    title="Privacy"
    articles={privacy}
    url="/help/privacy/"
    description="Find out informations how to keep your users privacy."
  />
);
